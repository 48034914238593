import { Box, Text } from 'grommet';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import ButtonLink from '../../ButtonLink';
import LinkInterface from '../../../interfaces/link.interface';

interface Props extends Omit<LinkInterface, 'roles'> {
  mobile: boolean;
}

const LinkItem = styled(Box)`
  padding-left: ${(props): string => props.theme.global.edgeSize.small};
  padding-right: ${(props): string => props.theme.global.edgeSize.large};
  padding-top: ${(props): string => props.theme.global.edgeSize.small};
  padding-bottom: ${(props): string => props.theme.global.edgeSize.medium};

  @media (min-width: ${(props): string => props.theme.global.size.large}) {
    padding-bottom: ${(props): string => props.theme.global.edgeSize.small};
  }
`;

const LinkText = styled(Text)`
  font-size: 18px;

  @media (min-width: ${(props): string => props.theme.global.size.large}) {
    font-size: 14px;
  }
`;

const SidebarItem: FC<Props> = ({ Icon, mobile, onClick, path, text }) => {
  const renderText = useCallback(
    (textValue: string) => {
      let size = 'small';
      let margin: Record<string, string> = { left: 'small' };

      if (mobile) {
        margin = {
          left: 'medium',
          vertical: '5px',
        };

        size = 'medium';
      }

      return (
        <LinkText margin={margin} color="white" size={size}>
          {textValue}
        </LinkText>
      );
    },
    [mobile],
  );

  return (
    <ButtonLink
      onClick={onClick}
      path={path}
      hoverIndicator={{
        color: 'white',
        opacity: 0.1,
      }}
    >
      <LinkItem direction="row" align="center">
        <Icon color="white" size="17px" />

        {renderText(text)}
      </LinkItem>
    </ButtonLink>
  );
};

export default SidebarItem;
