import React, { FC } from 'react';
import { Box, Header } from 'grommet';
import styled from 'styled-components';

import ButtonLink from '../ButtonLink';
import Logo from '../../images/logo-raoul-beaulieu.png';

const LogoImg = styled.img`
  width: 120px;
  @media (min-width: ${(props): string => props.theme.global.size.large}) {
    width: 180px;
  }
`;

const HeaderSC = styled(Header)`
  background: #101a3a;
  background: linear-gradient(to left, #004259, #101a3a);
`;

const LayoutSimple: FC = ({ children }) => {
  return (
    <>
      <HeaderSC background="brand" pad="medium">
        <LogoImg src={Logo} />
        <Box justify="end">
          <ButtonLink primary label="Se connecter" path={`/users/sign-in`} />
        </Box>
      </HeaderSC>

      <Box justify="center" align="center" flex pad="large">
        {children}
      </Box>
    </>
  );
};

export default LayoutSimple;
