import { some } from 'lodash';
import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import LayoutAuth from './LayoutAuth';
import LayoutMain from './LayoutMain';
import LayoutSimple from './LayoutSimple';
import Main from './Main';

const AUTH_PATHS = [/users\/forgotten-password/, /users\/sign/, /users\/reset-password/];

const Layout: FC = () => {
  const { pathname } = useLocation();

  const CurrentLayout = useMemo(() => {
    switch (true) {
      case some(AUTH_PATHS, path => path.test(pathname)): {
        return LayoutAuth;
      }
      case /quotes\/invitation/.test(pathname): {
        return LayoutSimple;
      }
      default: {
        return LayoutMain;
      }
    }
  }, [pathname]);

  return (
    <CurrentLayout>
      <Main />
    </CurrentLayout>
  );
};

export default Layout;
