import {
  ApolloClient,
  InMemoryCache,
  from,
  NormalizedCacheObject,
  Reference,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import moment from 'moment-timezone';

import { auth } from '../providers/AppProvider';

type PoliciesReferenceType = Reference | undefined;

const createApolloClient = (
  refreshToken: (
    apolloClient: ApolloClient<NormalizedCacheObject>,
    showToast: boolean,
  ) => Promise<void>,
): ApolloClient<NormalizedCacheObject> => {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            bider(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'Bider',
                id: args?.id,
              });
            },
            group(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'Group',
                id: args?.id,
              });
            },
            notification(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'Notification',
                id: args?.id,
              });
            },
            provider(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'Provider',
                id: args?.id,
              });
            },
            project(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'Project',
                id: args?.id,
              });
            },
            quote(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'Quote',
                id: args?.id,
              });
            },
            role(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'Role',
                id: args?.id,
              });
            },
            user(_, { args, toReference }): PoliciesReferenceType {
              return toReference({
                __typename: 'User',
                id: args?.id,
              });
            },
          },
        },
      },
    }),
    defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
  });

  const AuthLink = setContext(async (_, context) => {
    if (!context.skipAuth) {
      const tokenIsExpired = moment(auth.tokenExpiry).diff(moment()) <= 1000;

      if (tokenIsExpired) await refreshToken(apolloClient, true);
    }

    const { token } = auth;

    return {
      headers: {
        ...context.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  apolloClient.setLink(
    from([
      AuthLink,
      new BatchHttpLink({
        uri: `${process.env.REACT_APP_RBINC_API_URL}/graphql`,
        credentials: 'include',
      }),
    ]),
  );

  return apolloClient;
};

export default createApolloClient;
