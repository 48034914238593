import { useMutation, MutationTuple, OperationVariables } from '@apollo/client';

import SIGN_OUT_USER_MUTATION from '../../graphql/mutations/users/sign-out-user.mutation';

const useSignOutUser = (): MutationTuple<void, OperationVariables> => {
  return useMutation<void>(SIGN_OUT_USER_MUTATION, {
    context: { skipAuth: true },
  });
};

export default useSignOutUser;
