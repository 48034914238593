import Loadable from 'react-loadable';

import SpinnerPageLoad from '../components/SpinnerPageLoad';

export const DashboardPage = Loadable({
  loader: () => import('../components/dashboard/DashboardPage'),
  loading: SpinnerPageLoad,
});

export const BidersListPage = Loadable({
  loader: () => import('../components/biders/BidersListPage'),
  loading: SpinnerPageLoad,
});

export const CreateBiderPage = Loadable({
  loader: () => import('../components/biders/CreateBiderPage'),
  loading: SpinnerPageLoad,
});

export const EditBiderPage = Loadable({
  loader: () => import('../components/biders/EditBiderPage'),
  loading: SpinnerPageLoad,
});

export const FilesListPage = Loadable({
  loader: () => import('../components/files/FilesListPage'),
  loading: SpinnerPageLoad,
});

export const GroupsListPage = Loadable({
  loader: () => import('../components/groups/GroupsListPage'),
  loading: SpinnerPageLoad,
});

export const CreateGroupPage = Loadable({
  loader: () => import('../components/groups/CreateGroupPage'),
  loading: SpinnerPageLoad,
});

export const EditGroupPage = Loadable({
  loader: () => import('../components/groups/EditGroupPage'),
  loading: SpinnerPageLoad,
});

export const NotificationsListPage = Loadable({
  loader: () => import('../components/notifications/NotificationsListPage'),
  loading: SpinnerPageLoad,
});

export const CreateNotificationPage = Loadable({
  loader: () => import('../components/notifications/CreateNotificationPage'),
  loading: SpinnerPageLoad,
});

export const EditNotificationPage = Loadable({
  loader: () => import('../components/notifications/EditNotificationPage'),
  loading: SpinnerPageLoad,
});

export const ProvidersListPage = Loadable({
  loader: () => import('../components/providers/ProvidersListPage'),
  loading: SpinnerPageLoad,
});

export const CreateProviderPage = Loadable({
  loader: () => import('../components/providers/CreateProviderPage'),
  loading: SpinnerPageLoad,
});

export const EditProviderPage = Loadable({
  loader: () => import('../components/providers/EditProviderPage'),
  loading: SpinnerPageLoad,
});

export const UsersListPage = Loadable({
  loader: () => import('../components/users/UsersListPage'),
  loading: SpinnerPageLoad,
});

export const CreateUserPage = Loadable({
  loader: () => import('../components/users/CreateUserPage'),
  loading: SpinnerPageLoad,
});

export const EditUserPage = Loadable({
  loader: () => import('../components/users/EditUserPage'),
  loading: SpinnerPageLoad,
});

export const ProjectsListPage = Loadable({
  loader: () => import('../components/projects/ProjectsListPage'),
  loading: SpinnerPageLoad,
});

export const CreateProjectPage = Loadable({
  loader: () => import('../components/projects/CreateProjectPage'),
  loading: SpinnerPageLoad,
});

export const ProjectPage = Loadable({
  loader: () => import('../components/projects/ProjectPage'),
  loading: SpinnerPageLoad,
});

export const ProjectPageExternals = Loadable({
  loader: () => import('../components/projects/ProjectPageExternals'),
  loading: SpinnerPageLoad,
});

export const EditProjectPage = Loadable({
  loader: () => import('../components/projects/EditProjectPage'),
  loading: SpinnerPageLoad,
});

export const QuotesListPage = Loadable({
  loader: () => import('../components/quotes/QuotesListPage'),
  loading: SpinnerPageLoad,
});

export const CreateQuotePage = Loadable({
  loader: () => import('../components/quotes/CreateQuotePage'),
  loading: SpinnerPageLoad,
});

export const QuotePage = Loadable({
  loader: () => import('../components/quotes/QuotePage'),
  loading: SpinnerPageLoad,
});

export const QuotePageExternals = Loadable({
  loader: () => import('../components/quotes/QuotePageExternals'),
  loading: SpinnerPageLoad,
});

export const EditQuotePage = Loadable({
  loader: () => import('../components/quotes/EditQuotePage'),
  loading: SpinnerPageLoad,
});

export const AcceptInvitationPage = Loadable({
  loader: () => import('../components/quotes/biders/AcceptInvitationPage'),
  loading: SpinnerPageLoad,
});

export const Page404 = Loadable({
  loader: () => import('../components/Page404'),
  loading: SpinnerPageLoad,
});

export const SignInPage = Loadable({
  loader: () => import('../components/authentification/SignInPage'),
  loading: SpinnerPageLoad,
});

export const ForgottenPasswordPage = Loadable({
  loader: () => import('../components/authentification/ForgottenPasswordPage'),
  loading: SpinnerPageLoad,
});

export const ResetPasswordPage = Loadable({
  loader: () => import('../components/authentification/ResetPasswordPage'),
  loading: SpinnerPageLoad,
});

export const RedirectToShow = Loadable({
  loader: () => import('../components/routes/RedirectToShow'),
  loading: SpinnerPageLoad,
});
