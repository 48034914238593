import { Box } from 'grommet';
import React, { FC } from 'react';
import {
  Home,
  DollarSign,
  Clipboard,
  Briefcase,
  Package,
  Disc,
  Folder,
  MessageCircle,
  Users,
} from 'react-feather';

import SidebarItem from './SidebarItem';
import SidebarItemSignOut from './SidebarItemSignOut';
import SidebarListSeparator from './SidebarListSeparator';

interface Props {
  mobile?: boolean;
  onClick: () => void;
}

const SidebarListAdmin: FC<Props> = ({ onClick, mobile = false }) => {
  return (
    <>
      <Box flex={false}>
        <SidebarItem
          Icon={Home}
          path="/"
          mobile={mobile}
          onClick={onClick}
          text="Tableau de bord"
        />

        <SidebarItem
          Icon={DollarSign}
          path="/quotes"
          mobile={mobile}
          onClick={onClick}
          text="Soumissions"
        />

        <SidebarItem
          Icon={Clipboard}
          path="/projects"
          mobile={mobile}
          onClick={onClick}
          text="Projets"
        />

        <SidebarItem
          Icon={Package}
          path="/biders"
          mobile={mobile}
          onClick={onClick}
          text="Sous-traitants"
        />

        <SidebarItem
          Icon={Briefcase}
          path="/providers"
          mobile={mobile}
          onClick={onClick}
          text="Fournisseurs"
        />

        <SidebarItem
          Icon={Disc}
          path="/groups/region"
          mobile={mobile}
          onClick={onClick}
          text="Groupes"
        />

        <SidebarItem
          Icon={Folder}
          path="/files/general"
          mobile={mobile}
          onClick={onClick}
          text="Bibliothèque"
        />
      </Box>

      <SidebarListSeparator />

      <Box flex={false}>
        <SidebarItem
          Icon={MessageCircle}
          path="/notifications"
          mobile={mobile}
          onClick={onClick}
          text="Messages"
        />
        <SidebarItem Icon={Users} path="/users" mobile={mobile} onClick={onClick} text="Membres" />
      </Box>

      <SidebarListSeparator />

      <Box flex={false}>
        <SidebarItemSignOut mobile={mobile} />
      </Box>
    </>
  );
};

export default SidebarListAdmin;
