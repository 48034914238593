const global = {
  colors: {
    brand: '#101A3A',
    brandLight: '#00c1ff',
    brandLightShadow: '#9addfd',
    brandComplement: '#A7E521',
    borderLight: '#DCE1E7',
    borderExtraLight: '#EEF0F3',
    backgroundLightLayout: '#F2F3F9',
    backgroundOver: '#f0f8ff',
    backgroundActionLight: '#fbfbfb',
    actionLight: '#778699',
    currentText: '#101A3A',
    lightText: '#A5B4C6',
    icon: '#e1e1eb',
    placeholder: '#bcc0c5',
    statusCritical: '#FF3E1D',
    statusSuccess: '#4eca6f',
    statusInfo: '#e1e1eb',
    statusWarning: '#FFD422',
    'graph-0': '#4eca6f',
    background: {
      light: '#FFFFFF',
    },
    control: {
      light: '#101A3A', //= brand
    },
    text: {
      light: '#778699',
      dark: '#464e59',
    },
  },
  tag: {
    accepted: '#4eca6f',
    notified: '#f57c00',
    won: '#aa70c7',
    refused: '#ff3e1d',
    initial: '#3890dc',
  },
  control: {
    border: {
      width: '1px',
      radius: '8px',
      color: 'borderLight',
    },
    disabled: {
      opacity: 0.3,
    },
  },
  focus: {
    border: {
      color: 'brandLight',
    },
  },
  font: {
    family: 'Roboto',
    size: '14px',
    height: '20px',
  },
  input: {
    size: '100px',
    weight: 400,
  },
};

const theme = {
  defaultMode: 'light',
  global: {
    ...global,
  },
  accordion: {
    border: {
      color: 'borderExtraLight',
    },
    icons: {
      color: 'actionLight',
    },
  },
  anchor: {
    color: {
      light: '#0099ca',
    },
    fontWeight: 500,
  },
  button: {
    border: {
      color: {
        light: 'brandLightShadow',
      },
      width: '0px',
      radius: '6px',
    },
    padding: {
      vertical: '8px',
      horizontal: '20px',
    },
    color: {
      light: 'white',
    },
    primary: {
      color: {
        light: 'brandLight',
      },
    },
    size: {
      small: {
        border: {
          radius: '6px',
        },
        pad: {
          horizontal: '10px',
          vertical: '5px',
        },
      },
      large: {
        border: {
          radius: '6px',
        },
        pad: {
          horizontal: '24px',
          vertical: '10px',
        },
      },
    },
    transition: {
      duration: 0.5,
    },
  },
  checkBox: {
    border: {
      color: 'borderLight',
    },
    color: 'brandLight',
    icon: {
      size: '15px',
    },
    hover: {
      border: {
        color: 'brandLight',
      },
    },
    toggle: {
      color: 'red',
    },

    extend: (): string => {
      return `

      `;
    },
  },

  formField: {
    extend: (): string => {
      return `
          label + div,
          > div {
            border: none;
          }
          label + div + span:last-of-type {
            font-size: 14px;
            font-weight: 600;
          }
        `;
    },
    border: {
      color: 'transparent',
    },
    error: {
      color: global.colors.statusCritical,
      margin: {
        horizontal: '0',
      },
    },
    label: {
      color: 'currentText',
      size: '14px',
      weight: 500,
      margin: {
        horizontal: '0',
      },
    },
    margin: {
      bottom: 'medium',
    },
  },
  heading: {
    level: {
      1: {
        medium: { size: '43px' },
      },
      3: {
        medium: { maxWidth: 'auto' },
      },
    },
  },
  layer: {
    overlay: {
      background: 'rgba(0, 0, 0, 0.8)',
    },
  },
  paragraph: {
    small: {
      size: '12px',
      height: '16px',
      maxWidth: 'auto',
    },
    medium: {
      size: '16px',
      height: '22px',
      maxWidth: 'auto',
    },
    large: {
      size: '22px',
      height: '28px',
      maxWidth: '528px',
    },
    xlarge: {
      size: '26px',
      height: '32px',
      maxWidth: '624px',
    },
    xxlarge: {
      size: '34px',
      height: '40px',
      maxWidth: '816px',
    },
  },
  text: {
    light: '#778699',
    small: {
      size: '14px',
      height: '20px',
      maxWidth: '400px',
    },
    medium: {
      size: '16px',
      height: '22px',
      maxWidth: '400px',
    },
  },
  tab: {
    extend: (): string => {
      return `
      padding: 13px 20px;
      margin-bottom: -1px;
      span {
        font-size: 14px !important;
        font-weight: 500 !important;
      }                           
    `;
    },
    active: {
      color: 'brandLight',
    },
    border: {
      color: 'transparent',
      size: '2px',
      active: {
        color: 'brandLight',
        size: '2px',
      },
      hover: {
        color: 'transparent',
      },
    },
    color: 'lightText ',
    hover: {
      color: 'brandLight',
    },
  },
  tabs: {
    extend: (): string => {
      return `
      button {
        box-shadow: none;
      }                           
    `;
    },
  },
  table: {
    extend: (): string => {
      return `
          tbody {
            outline: none;
            box-shadow: none;
          }
        `;
    },
    header: {
      pad: {
        vertical: 'small',
      },
      extend: (): string => {
        return `
            color: ${global.colors.lightText};
            border-bottom-color: ${global.colors.borderExtraLight} !important;
            font-size: 14px;
            button {
              box-shadow: none;
              &:hover {
                color:  ${global.colors.brandLight};
                svg * {
                  stroke: ${global.colors.brandLight} !important;
                }
              }
            }    
            span{
              font-size: 13px;
              text-transform: uppercase;
            }
            svg{
              width: 15px;
              height: 15px;
              
              * {
                stroke: ${global.colors.lightText} !important;
              }
            }
          `;
      },
    },
    body: {
      pad: {
        vertical: '13px',
      },
      extend: (): string => {
        return `
            border-bottom: solid 1px ${global.colors.borderExtraLight};
            vertical-align: top;
            span {
              color: ${global.colors.currentText};
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          `;
      },
    },
  },
  textArea: {
    extend: (): string => {
      return `
          color: ${global.colors.currentText};
          min-height: 175px;
          &:focus {
            box-shadow: none;
          }
        `;
    },
  },
  textInput: {
    extend: (): string => {
      return `
          color: ${global.colors.currentText};
          &:focus {
            box-shadow: none;
          }
        `;
    },
  },
};

export default theme;
