import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  backgroundLayout?: boolean;
  verticallyCentered?: boolean;
}

const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Wrapper = styled.span<Props>`
  ${(props): string =>
    props.verticallyCentered
      ? `
          display: flex;
          height: 100%;
          align-items: center;
        `
      : ''}
`;

const SpinnerSC = styled.div<Props>`
  border-radius: 50%;
  color: ${({ theme }): string => theme.global.colors.icon};
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0) scale(0.8);

  &:before,
  &:after {
    position: absolute;
    top: -0.1em;
    content: '';
    width: 5.2em;
    height: 10.2em;
    border-radius: 50%;
    background: ${(props): string =>
      props.backgroundLayout ? props.theme.global.colors.backgroundLightLayout : 'white'};
  }

  &:before {
    border-radius: 10.2em 0 0 10.2em;
    left: -0.1em;
    transform-origin: 5.1em 5.1em;
    animation: ${rotate} 2s infinite ease 1.5s;
  }
  &:after {
    border-radius: 0 10.2em 10.2em 0;
    left: 4.9em;
    transform-origin: 0.1em 5.1em;
    animation: ${rotate} 2s infinite ease;
  }
`;

const Spinner: FC<Props> = ({ backgroundLayout, verticallyCentered }) => {
  return (
    <Wrapper verticallyCentered={verticallyCentered}>
      <SpinnerSC backgroundLayout={backgroundLayout} />
    </Wrapper>
  );
};

export default Spinner;
