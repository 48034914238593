import { ButtonType } from 'grommet';
import React, { FC, useCallback, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import Button from './Button';

interface Props {
  path?: string;
}

const ButtonLink: FC<Props & ButtonType> = ({ children, onClick, path, ...props }) => {
  const history = useHistory();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!event.ctrlKey && !event.metaKey) {
        event.preventDefault();

        if (onClick) onClick(event);
        if (path) history.push(path);
      }
    },
    [history, onClick, path],
  );

  return (
    <Button {...props} onClick={handleClick} href={path} ref={null}>
      {children}
    </Button>
  );
};

export default ButtonLink;
