import React, { FC } from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';

import { useAuth } from '../../providers/AppProvider';

const AuthRoute: FC<RouteProps> = props => {
  const { currentUser } = useAuth();

  return currentUser.id ? <Redirect to="/" /> : <Route {...props} />;
};

export default AuthRoute;
