import React, { FC, useMemo } from 'react';

import RoutesAdmin from '../routes/RoutesAdmin';
import RoutesExternals from '../routes/RoutesExternals';
import RoutesInternals from '../routes/RoutesInternals';
import RoleNamesEnum from '../../enums/roles/role-names.enum';
import { useAuth } from '../../providers/AppProvider';

const { ADMIN, BIDER, PROVIDER, SUPERADMIN } = RoleNamesEnum;

const Main: FC = () => {
  const { currentUser } = useAuth();

  const Routes = useMemo(() => {
    const { role } = currentUser;

    switch (role) {
      case ADMIN:
      case SUPERADMIN: {
        return RoutesAdmin;
      }
      case BIDER:
      case PROVIDER: {
        return RoutesExternals;
      }
      default: {
        return RoutesInternals;
      }
    }
  }, [currentUser]);

  return <Routes />;
};

export default Main;
