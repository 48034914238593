import styled from 'styled-components';

const SidebarListSeparator = styled.hr`
  width: 100%;
  margin: 16px 0;
  border-top: 0;
  opacity: 0.2;
`;

export default SidebarListSeparator;
