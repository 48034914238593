import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthRoute from '../authentification/AuthRoute';
import PrivateRoute from '../authentification/PrivateRoute';
import {
  DashboardPage,
  BidersListPage,
  CreateBiderPage,
  EditBiderPage,
  GroupsListPage,
  CreateGroupPage,
  EditGroupPage,
  FilesListPage,
  NotificationsListPage,
  CreateNotificationPage,
  EditNotificationPage,
  ProvidersListPage,
  CreateProviderPage,
  EditProviderPage,
  ProjectsListPage,
  CreateProjectPage,
  EditProjectPage,
  QuotesListPage,
  CreateQuotePage,
  EditQuotePage,
  UsersListPage,
  CreateUserPage,
  EditUserPage,
  ForgottenPasswordPage,
  ResetPasswordPage,
  SignInPage,
  AcceptInvitationPage,
  Page404,
} from '../../utils/pages';

const RoutesAdmin: FC = () => {
  useEffect(() => {
    [
      DashboardPage,
      BidersListPage,
      CreateBiderPage,
      EditBiderPage,
      FilesListPage,
      GroupsListPage,
      CreateGroupPage,
      EditGroupPage,
      NotificationsListPage,
      CreateNotificationPage,
      EditNotificationPage,
      ProvidersListPage,
      CreateProviderPage,
      EditProviderPage,
      UsersListPage,
      CreateUserPage,
      EditUserPage,
      ProjectsListPage,
      CreateProjectPage,
      EditProjectPage,
      QuotesListPage,
      CreateQuotePage,
      EditQuotePage,
      AcceptInvitationPage,
      Page404,
      SignInPage,
      ForgottenPasswordPage,
      ResetPasswordPage,
    ].forEach(page => page.preload());
  }, []);

  const resetType = ':type(invitation|reset)';

  return (
    <Switch>
      <PrivateRoute path="/" component={DashboardPage} exact />
      <PrivateRoute path="/biders" component={BidersListPage} exact />
      <PrivateRoute path="/biders/new" component={CreateBiderPage} exact />
      <PrivateRoute path="/biders/:id/edit" component={EditBiderPage} exact />
      <PrivateRoute path="/groups/:type(bider|provider|region)" component={GroupsListPage} exact />
      <PrivateRoute
        path="/groups/:type(bider|provider|region)/new"
        component={CreateGroupPage}
        exact
      />
      <PrivateRoute path="/groups/:id/edit" component={EditGroupPage} exact />
      <PrivateRoute
        path="/files/:id(general|superintendent|foreman|estimator|admin)"
        component={FilesListPage}
        exact
      />
      <PrivateRoute
        path="/files/:id(general|superintendent|foreman|estimator|admin)/:directoryId"
        component={FilesListPage}
        exact
      />
      <PrivateRoute path="/notifications" component={NotificationsListPage} exact />
      <PrivateRoute path="/notifications/new" component={CreateNotificationPage} exact />
      <PrivateRoute path="/notifications/:id/edit" component={EditNotificationPage} exact />
      <PrivateRoute path="/providers" component={ProvidersListPage} exact />
      <PrivateRoute path="/providers/new" component={CreateProviderPage} exact />
      <PrivateRoute path="/providers/:id/edit" component={EditProviderPage} exact />
      <PrivateRoute path="/projects" component={ProjectsListPage} exact />
      <PrivateRoute path="/projects/new" component={CreateProjectPage} exact />
      <PrivateRoute
        path="/projects/:id/:tab(details|biders|providers|files)/edit"
        component={EditProjectPage}
        exact
      />
      <PrivateRoute
        path="/projects/:id/files/:directoryId/edit"
        component={EditProjectPage}
        exact
      />
      <PrivateRoute path="/quotes" component={QuotesListPage} exact />
      <PrivateRoute path="/quotes/new" component={CreateQuotePage} exact />
      <PrivateRoute
        path="/quotes/:id/:tab(details|biders|providers|files)/edit"
        component={EditQuotePage}
        exact
      />
      <PrivateRoute path="/quotes/:id/files/:directoryId/edit" component={EditQuotePage} exact />
      <PrivateRoute path="/users" component={UsersListPage} exact />
      <PrivateRoute path="/users/new" component={CreateUserPage} exact />
      <PrivateRoute path="/users/:id/edit" component={EditUserPage} exact />
      <AuthRoute
        path={`/users/forgotten-password/${resetType}`}
        component={ForgottenPasswordPage}
        exact
      />
      <AuthRoute
        path={`/users/reset-password/${resetType}/:token`}
        component={ResetPasswordPage}
        exact
      />
      <AuthRoute path="/users/sign-in" component={SignInPage} exact />
      <Route
        path="/quotes/invitation/:type(bider|provider)/:token"
        component={AcceptInvitationPage}
        exact
      />
      <PrivateRoute component={Page404} />
    </Switch>
  );
};

export default RoutesAdmin;
