import React, { FC, useEffect, ReactElement } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AuthRoute from '../authentification/AuthRoute';
import PrivateRoute from '../authentification/PrivateRoute';
import {
  DashboardPage,
  ProjectsListPage,
  QuotesListPage,
  ForgottenPasswordPage,
  ResetPasswordPage,
  SignInPage,
  AcceptInvitationPage,
  Page404,
  QuotePageExternals,
  ProjectPageExternals,
  RedirectToShow,
} from '../../utils/pages';

const RoutesExternals: FC = () => {
  useEffect(() => {
    [
      DashboardPage,
      ProjectsListPage,
      QuotesListPage,
      AcceptInvitationPage,
      Page404,
      SignInPage,
      ForgottenPasswordPage,
      ResetPasswordPage,
      QuotePageExternals,
      ProjectPageExternals,
      RedirectToShow,
    ].forEach(page => page.preload());
  }, []);

  const tabsParam = ':tab(details|invitations|files)';
  const resetType = ':type(invitation|reset)';

  return (
    <Switch>
      <PrivateRoute path="/" render={(): ReactElement => <Redirect to="/quotes" />} exact />
      <PrivateRoute path="/projects" component={ProjectsListPage} exact />
      <PrivateRoute path={`/projects/:id/${tabsParam}`} component={ProjectPageExternals} exact />
      <PrivateRoute path={`/projects/:id/${tabsParam}/edit`} component={RedirectToShow} exact />
      <PrivateRoute
        path="/projects/:id/files/:directoryId"
        component={ProjectPageExternals}
        exact
      />
      <PrivateRoute path="/quotes" component={QuotesListPage} exact />
      <PrivateRoute path={`/quotes/:id/${tabsParam}`} component={QuotePageExternals} exact />
      <PrivateRoute path={`/quotes/:id/${tabsParam}/edit`} component={RedirectToShow} exact />
      <PrivateRoute path="/quotes/:id/files/:directoryId" component={QuotePageExternals} exact />
      <AuthRoute
        path={`/users/forgotten-password/${resetType}`}
        component={ForgottenPasswordPage}
        exact
      />
      <AuthRoute
        path={`/users/reset-password/${resetType}/:token`}
        component={ResetPasswordPage}
        exact
      />
      <AuthRoute path="/users/sign-in" component={SignInPage} exact />
      <Route
        path="/quotes/invitation/:type(bider|provider)/:token"
        component={AcceptInvitationPage}
        exact
      />
      <PrivateRoute component={Page404} />
    </Switch>
  );
};

export default RoutesExternals;
