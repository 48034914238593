import React, { FC } from 'react';
import styled from 'styled-components';

import LogoHubblePath from '../../images/logo-hubble.png';

const LogoHubbleImg = styled.img`
  width: 90px;
`;

const LogoHubble: FC = () => {
  return (
    <a href="https://teamhubble.com" target="_blank" rel="noopener noreferrer">
      <LogoHubbleImg src={LogoHubblePath} />
    </a>
  );
};

export default LogoHubble;
