import React, { forwardRef, RefForwardingComponent } from 'react';
import { Button as ButtonG, ButtonType } from 'grommet';
import styled from 'styled-components';

interface Props {
  primary?: boolean;
  secondary?: boolean;
  critical?: boolean;
  important?: boolean;
}

const ButtonSC = styled(ButtonG)<Props>`
  color: ${(props): string =>
    props.critical
      ? props.theme.global.colors.statusCritical
      : props.theme.global.colors.actionLight};
  border: none;
  font-size: ${(props): string => (props.size === 'small' ? '14px' : '16px')};
  font-weight: ${(props): string => (props.critical ? '500' : '400')};
  white-space: nowrap;
  background: none;
  cursor: pointer

  &:hover {
    color: ${(props): string =>
      props.critical
        ? props.theme.global.colors.statusCritical
        : props.theme.global.colors.brandLight};
    background: ${(props): string => (props.critical ? '#ffeeec' : 'inherit')};
    box-shadow: none;
  }

  ${(props): string => {
    switch (true) {
      case props.critical && props.secondary: {
        return `
          background:  none;
          border: none;
          color: ${props.theme.global.colors.statusCritical};
          font-weight: 500;
    
          &:hover {
            background: none;
            box-shadow: none;
          }

          svg {
            stroke: white;
          }
        `;
      }
      case props.critical: {
        return `
          background:  ${props.theme.global.colors.statusCritical};
          border: solid 1px  ${props.theme.global.colors.statusCritical}
          box-shadow: 0px 0px 0px 2px  #ffeeec;
          color: white;
          font-weight: 500;

          &:hover {
            background: ${props.theme.global.colors.statusCritical};
            border-color: ${props.theme.global.colors.statusCritical};
            box-shadow: 0px 0px 0px 2px ${props.theme.global.colors.statusCritical};
            color: white;
          }

          svg {
            stroke: white;
          }
        `;
      }

      case props.primary: {
        return `
          background:  ${
            props.critical
              ? props.theme.global.colors.statusCritical
              : props.theme.global.colors.brandLight
          };
    
          border: solid 1px  ${
            props.critical
              ? props.theme.global.colors.statusCritical
              : props.theme.global.colors.brandLight
          };
    
          color: white;
          font-weight: 500;
    
          &:hover {
            background: ${
              props.critical
                ? props.theme.global.colors.statusCritical
                : props.theme.global.colors.brandLight
            };
    
          color: white;
          box-shadow: 0px 0px 0px 2px  ${
            props.critical ? '#ffeeec' : props.theme.global.colors.brandLightShadow
          };
          }
          
          svg {
            stroke: white;
          }
        `;
      }
      case props.secondary: {
        return `
          background: white;
          border-width: ${props.plain ? '0' : '1px'};
          border-color: ${props.theme.global.colors.brandLight};
          border-style: solid;
          color: ${props.theme.global.colors.brandLight};
          font-weight: 500;
    
          &:hover {
            box-shadow: 0px 0px 0px 2px ${props.theme.global.colors.brandLightShadow};
          }
    
          svg {
            stroke: brandLight;
          }
        `;
      }
      case props.important: {
        return `
          background: ${props.theme.global.colors.statusSuccess};
          border-width: ${props.plain ? '0' : '1px'};
          border-color: ${props.theme.global.colors.statusSuccess};
          border-style: solid;
          color: white;
          font-weight: 500;
    
          &:hover {
            background: ${props.theme.global.colors.statusSuccess};
            border-color: ${props.theme.global.colors.statusSuccess};
            box-shadow: 0px 0px 0px 2px ${props.theme.global.colors.statusSuccess};
            color: white;
          }
    
          svg {
            stroke: ${props.theme.global.colors.statusSuccess};
          }
        `;
      }
      default: {
        return '';
      }
    }
  }}
`;

const Button: RefForwardingComponent<HTMLButtonElement, Props & ButtonType> = (props, ref) => {
  return <ButtonSC focusIndicator={false} ref={ref} {...props} />;
};

export default forwardRef(Button);
