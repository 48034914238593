import React, { FC } from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';

import { useAuth } from '../../providers/AppProvider';

const PrivateRoute: FC<RouteProps> = props => {
  const { currentUser } = useAuth();

  return currentUser.id ? <Route {...props} /> : <Redirect to="/users/sign-in" />;
};

export default PrivateRoute;
