import { Box } from 'grommet';
import React, { FC } from 'react';
import styled from 'styled-components';

import Logo from '../../../images/logo-raoul-beaulieu.png';

const LogoImg = styled.img`
  width: 127px;
`;

const HeaderDesktop: FC = () => {
  return (
    <>
      <Box background="brand" gridArea="header" justify="center" pad="medium">
        <LogoImg src={Logo} />
      </Box>
    </>
  );
};

export default HeaderDesktop;
