import { ApolloProvider } from '@apollo/client';
import React, {
  FC,
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';

import createApolloClient from '../utils/apollo-client';
import AuthInterface from '../interfaces/auth.interface';
import JwtPayloadInterface from '../interfaces/jwt/jwt-payload.interface';
import Spinner from '../components/Spinner';
import RefreshTokenService from '../services/jwt/refresh-token.service';
import { toast } from 'react-toastify';

interface ContextInterface {
  currentUser: JwtPayloadInterface;
  setAuth: (auth?: AuthInterface) => void;
}

export const DEFAULT_AUTH: AuthInterface = { token: '', tokenExpiry: 0 };
export const DEFAULT_CURRENT_USER: JwtPayloadInterface = { id: '', role: '' };

export let auth = DEFAULT_AUTH;

const authContext = createContext<ContextInterface>({
  ...auth,
  currentUser: DEFAULT_CURRENT_USER,
  setAuth: () => {
    throw new Error('setAuth must be overridden');
  },
});

export const AppProvider: FC = ({ children }) => {
  const [mounting, setMounting] = useState(true);
  const [{ token }, setAuth] = useState(DEFAULT_AUTH);

  const handleSetAuth = useRef((newAuth?: AuthInterface) => {
    auth = newAuth ?? DEFAULT_AUTH;

    setAuth(auth);
  }).current;

  const handleRefreshToken = useCallback(
    async (apolloClient, showToast = false) => {
      const auth = await RefreshTokenService.refreshToken(apolloClient);

      handleSetAuth(auth);

      if (showToast && !auth) toast.error('Veuillez vous reconnecter');

      setMounting(false);
    },
    [handleSetAuth],
  );

  const apolloClient = useRef(createApolloClient(handleRefreshToken)).current;

  const currentUser = useMemo(() => {
    if (!token) return DEFAULT_CURRENT_USER;

    return JSON.parse(atob(token.split('.')[1]));
  }, [token]);

  useEffect(() => {
    handleRefreshToken(apolloClient);
  }, [apolloClient, handleRefreshToken]);

  if (mounting) return <Spinner verticallyCentered />;

  return (
    <authContext.Provider value={{ currentUser, setAuth: handleSetAuth }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </authContext.Provider>
  );
};

export const useAuth = (): ContextInterface => useContext(authContext);
