import { Box } from 'grommet';
import React, { FC } from 'react';

const SidebarDesktop: FC = ({ children }) => {
  return (
    <Box
      background="brand"
      border={false}
      gridArea="sidebar"
      overflow={{ vertical: 'auto', horizontal: 'hidden' }}
      pad={{
        top: 'small',
      }}
    >
      {children}
    </Box>
  );
};

export default SidebarDesktop;
