import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import REFRESH_TOKEN_MUTATION from '../../graphql/mutations/jwt/refresh-token.mutation';
import AuthInterface from '../../interfaces/auth.interface';

class RefreshTokenService {
  static async refreshToken(
    apolloClient: ApolloClient<NormalizedCacheObject>,
  ): Promise<AuthInterface | undefined> {
    try {
      const { data } = await apolloClient.mutate({
        mutation: REFRESH_TOKEN_MUTATION,
        fetchPolicy: 'no-cache',
        context: { skipAuth: true },
      });

      return data.refreshToken;
    } catch (error) {}
  }
}

export default RefreshTokenService;
