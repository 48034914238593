import { Box } from 'grommet';
import { Menu } from 'react-feather';
import React, { FC } from 'react';
import styled from 'styled-components';

import Logo from '../../../images/logo-raoul-beaulieu.png';

const LogoImg = styled.img`
  height: 40px;
`;

interface Props {
  onOpenMenu: () => void;
}

const HeaderMobile: FC<Props> = ({ onOpenMenu }) => {
  return (
    <>
      <Box
        background="brand"
        border={{
          color: 'borderLayout',
          size: 'xsmall',
          style: 'solid',
          side: 'bottom',
        }}
        direction="row"
        gridArea="header"
        justify="between"
        align="center"
        pad="medium"
      >
        <LogoImg src={Logo} />

        <div onClick={onOpenMenu}>
          <Menu color="white" size={30} />
        </div>
      </Box>
    </>
  );
};

export default HeaderMobile;
