import 'moment/locale/fr';

import { Grommet } from 'grommet';
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import ToastContainer from './ToastContainer';
import Layout from './layout/Layout';
import theme from '../theme';

import { AppProvider } from '../providers/AppProvider';

const App: FC = () => {
  return (
    <BrowserRouter>
      <Grommet theme={theme} full>
        <AppProvider>
          <Layout />
          <ToastContainer />
        </AppProvider>
      </Grommet>
    </BrowserRouter>
  );
};

export default App;
