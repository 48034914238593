import { useApolloClient } from '@apollo/client';
import React, { FC, useCallback } from 'react';
import { LogOut } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import SidebarItem from './SidebarItem';
import useSignOutUser from '../../../hooks/users/sign-out-user.hook';
import { useAuth } from '../../../providers/AppProvider';

interface Props {
  mobile: boolean;
}

const SidebarItemSignOut: FC<Props> = ({ mobile }) => {
  const apolloClient = useApolloClient();
  const { setAuth } = useAuth();
  const [signOutUser] = useSignOutUser();
  const history = useHistory();

  const handleSignOut = useCallback(async () => {
    try {
      await signOutUser({ context: { skipAuth: true } });

      setAuth();

      history.replace('/users/sign-in');

      apolloClient.clearStore();

      toast.success('Déconnecté avec succès');
    } catch (_) {
      toast.error('Erreur lors de la tentative de déconnexion');
    }
  }, [apolloClient, history, setAuth, signOutUser]);

  return <SidebarItem text="Déconnexion" Icon={LogOut} mobile={mobile} onClick={handleSignOut} />;
};

export default SidebarItemSignOut;
