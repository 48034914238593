import { gql } from '@apollo/client';

const REFRESH_TOKEN_MUTATION = gql`
  mutation REFRESH_TOKEN_MUTATION {
    refreshToken {
      token
      tokenExpiry
    }
  }
`;

export default REFRESH_TOKEN_MUTATION;
