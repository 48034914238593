enum RoleNamesEnum {
  ADMIN = 'ADMIN',
  BIDER = 'BIDER',
  EMPLOYEE = 'EMPLOYEE',
  ESTIMATOR = 'ESTIMATOR',
  FOREMAN = 'FOREMAN',
  PROVIDER = 'PROVIDER',
  SUPERADMIN = 'SUPERADMIN',
  SUPERINTENDENT = 'SUPERINTENDENT',
}

export default RoleNamesEnum;
