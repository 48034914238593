import { Box, Layer } from 'grommet';
import React, { FC } from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';

interface Props {
  onCloseMenu: () => void;
}

const CloseIconWrapper = styled(Box)`
  min-height: 55px;
`;

const SidebarMobile: FC<Props> = ({ children, onCloseMenu }) => {
  return (
    <Layer animation="slide" full="vertical" position="right">
      <Box background="brand" fill>
        <CloseIconWrapper
          pad={{
            top: 'medium',
            bottom: 'medium',
            right: 'medium',
          }}
          justify="end"
          align="end"
        >
          <X onClick={onCloseMenu} color="white" size={30} />
        </CloseIconWrapper>

        <Box overflow="auto" pad="large">
          {children}
        </Box>
      </Box>
    </Layer>
  );
};

export default SidebarMobile;
