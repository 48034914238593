import React, { FC } from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';

import Logo from '../../images/logo-authentification.png';
import LogoHubble from './LogoHubble';

const LogoImg = styled.img`
  width: 390px;
  opacity: 0.7;
`;

const LogoMobileImg = styled.img`
  width: 200px;
  margin: 0 auto 10px auto;
`;

const HeaderMobile = styled.header`
  background: linear-gradient(to left, #004259, #101a3a);
  text-align: center;
  @media (min-width: ${(props): string => props.theme.global.size.large}) {
    display: none;
  }
`;

const HeroBox = styled(Box)`
  display: none;
  background: #101a3a;
  background: linear-gradient(to left, #004259, #101a3a);

  @media (min-width: ${(props): string => props.theme.global.size.large}) {
    display: flex;
    width: 50%;
    max-width: 1000px;
  }
  @media (min-width: ${(props): string => props.theme.global.size.xlarge}) {
    width: 66%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;

  @media (min-width: ${(props): string => props.theme.global.size.large}) {
  }
`;

const Wrapper = styled(Box)`
  @media (min-width: ${(props): string => props.theme.global.size.large}) {
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
`;

const LogoHubbleWrapper = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const LayoutAuth: FC = ({ children }) => {
  return (
    <Wrapper>
      <HeroBox justify="center" align="center" flex background="brand">
        <LogoImg src={Logo} />
      </HeroBox>

      <HeaderMobile>
        <LogoMobileImg src={Logo} />
      </HeaderMobile>

      <Box justify="center" align="center" flex pad="large">
        <FormContainer>
          {children}
          <LogoHubbleWrapper>
            <LogoHubble />
          </LogoHubbleWrapper>
        </FormContainer>
      </Box>
    </Wrapper>
  );
};

export default LayoutAuth;
