import { Box } from 'grommet';
import React, { FC } from 'react';
import { DollarSign, Clipboard } from 'react-feather';

import SidebarItem from './SidebarItem';
import SidebarItemSignOut from './SidebarItemSignOut';
import SidebarListSeparator from './SidebarListSeparator';

interface Props {
  mobile?: boolean;
  onClick: () => void;
}

const SidebarListExternals: FC<Props> = ({ onClick, mobile = false }) => {
  return (
    <>
      <Box flex={false}>
        <SidebarItem
          Icon={DollarSign}
          path="/quotes"
          mobile={mobile}
          onClick={onClick}
          text="Soumissions"
        />
        <SidebarItem
          Icon={Clipboard}
          path="/projects"
          mobile={mobile}
          onClick={onClick}
          text="Projets"
        />
      </Box>

      <SidebarListSeparator />

      <Box flex={false}>
        <SidebarItemSignOut mobile={mobile} />
      </Box>
    </>
  );
};

export default SidebarListExternals;
