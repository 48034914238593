import { Box, Grid, ResponsiveContext } from 'grommet';
import React, { FC, useContext, useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import HeaderDesktop from './Header/HeaderDesktop';
import HeaderMobile from './Header/HeaderMobile';
import LogoHubble from './LogoHubble';
import SidebarDesktop from './Sidebar/SidebarDesktop';
import SidebarListAdmin from './Sidebar/SidebarListAdmin';
import SidebarListExternals from './Sidebar/SidebarListExternals';
import SidebarListInternals from './Sidebar/SidebarListInternals';
import SidebarMobile from './Sidebar/SidebarMobile';
import RoleNamesEnum from '../../enums/roles/role-names.enum';
import { useAuth } from '../../providers/AppProvider';

const { ADMIN, BIDER, PROVIDER, SUPERADMIN } = RoleNamesEnum;

const LogoHubbleWrapper = styled(Box)`
  min-height: auto;
`;

const LayoutMain: FC = ({ children }) => {
  const { currentUser } = useAuth();
  const responsive = useContext(ResponsiveContext);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const history = useHistory();

  const handleOpenSidebar = useRef(() => setSidebarOpened(true)).current;
  const handleCloseSidebar = useRef(() => setSidebarOpened(false)).current;

  const handleCloseMenu = useCallback(
    mobile => (): void => {
      if (mobile) handleCloseSidebar();
    },
    [handleCloseSidebar],
  );

  const SidebarList = useMemo(() => {
    const { role } = currentUser;

    switch (role) {
      case ADMIN:
      case SUPERADMIN: {
        return SidebarListAdmin;
      }
      case BIDER:
      case PROVIDER: {
        return SidebarListExternals;
      }
      default: {
        return SidebarListInternals;
      }
    }
  }, [currentUser]);

  const areas = useMemo(() => {
    if (responsive === 'medium' || responsive === 'large') {
      return [
        { name: 'header', start: [0, 0], end: [0, 0] },
        { name: 'sidebar', start: [0, 1], end: [0, 1] },
        { name: 'main', start: [1, 0], end: [1, 1] },
      ];
    }

    return [
      { name: 'header', start: [0, 0], end: [1, 0] },
      { name: 'main', start: [0, 1], end: [1, 1] },
    ];
  }, [responsive]);

  const renderMobileSidebar = useMemo(() => {
    if (!sidebarOpened) return null;

    return (
      <SidebarMobile onCloseMenu={handleCloseSidebar}>
        <SidebarList onClick={handleCloseMenu(true)} />
      </SidebarMobile>
    );
  }, [handleCloseMenu, handleCloseSidebar, sidebarOpened]);

  const renderNavigation = useMemo(() => {
    if (responsive === 'medium' || responsive === 'large') {
      return (
        <>
          <HeaderDesktop />
          <SidebarDesktop>
            <SidebarList onClick={handleCloseMenu(false)} />
          </SidebarDesktop>
        </>
      );
    }

    return (
      <>
        <HeaderMobile onOpenMenu={handleOpenSidebar} />
        {renderMobileSidebar}
      </>
    );
  }, [handleCloseMenu, handleOpenSidebar, renderMobileSidebar, responsive]);

  const layoutBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return history.listen(() => {
      layoutBoxRef.current?.scrollTo(0, 0);
    });
  }, [history]);

  return (
    <Grid fill rows={['auto', 'flex']} columns={['auto', 'flex']} areas={areas}>
      {renderNavigation}
      <Box
        gridArea="main"
        background="backgroundLightLayout"
        overflow="auto"
        ref={layoutBoxRef}
        justify="between"
      >
        <div>{children}</div>

        <LogoHubbleWrapper
          justify="end"
          direction="row"
          pad={{ top: 'small', right: 'medium', bottom: 'small', left: 'small' }}
        >
          <LogoHubble />
        </LogoHubbleWrapper>
      </Box>
    </Grid>
  );
};

export default LayoutMain;
