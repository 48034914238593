import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthRoute from '../authentification/AuthRoute';
import PrivateRoute from '../authentification/PrivateRoute';
import {
  AcceptInvitationPage,
  DashboardPage,
  FilesListPage,
  ForgottenPasswordPage,
  ProjectsListPage,
  QuotesListPage,
  QuotePage,
  ResetPasswordPage,
  SignInPage,
  Page404,
  ProjectPage,
  RedirectToShow,
} from '../../utils/pages';
import { useAuth } from '../../providers/AppProvider';

const RoutesInternals: FC = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    [
      DashboardPage,
      FilesListPage,
      ProjectsListPage,
      ProjectPage,
      QuotesListPage,
      QuotePage,
      AcceptInvitationPage,
      Page404,
      SignInPage,
      ForgottenPasswordPage,
      ResetPasswordPage,
      RedirectToShow,
    ].forEach(page => page.preload());
  }, []);

  const fileIdParam = `:id(general|${currentUser.role.toLowerCase()})`;
  const tabsParam = ':tab(details|biders|providers|files)';
  const resetType = ':type(invitation|reset)';

  return (
    <Switch>
      <PrivateRoute path="/" component={DashboardPage} exact />
      <PrivateRoute path={`/files/${fileIdParam}`} component={FilesListPage} exact />
      <PrivateRoute path={`/files/${fileIdParam}/:directoryId`} component={FilesListPage} exact />
      <PrivateRoute path="/projects" component={ProjectsListPage} exact />
      <PrivateRoute path={`/projects/:id/${tabsParam}`} component={ProjectPage} exact />
      <PrivateRoute path={`/projects/:id/${tabsParam}/edit`} component={RedirectToShow} exact />
      <PrivateRoute path="/projects/:id/files/:directoryId" component={ProjectPage} exact />
      <PrivateRoute path="/quotes" component={QuotesListPage} exact />
      <PrivateRoute path={`/quotes/:id/${tabsParam}`} component={QuotePage} exact />
      <PrivateRoute path={`/quotes/:id/${tabsParam}/edit`} component={RedirectToShow} exact />
      <PrivateRoute path="/quotes/:id/files/:directoryId" component={QuotePage} exact />
      <AuthRoute
        path={`/users/forgotten-password/${resetType}`}
        component={ForgottenPasswordPage}
        exact
      />
      <AuthRoute
        path={`/users/reset-password/${resetType}/:token`}
        component={ResetPasswordPage}
        exact
      />
      <AuthRoute path="/users/sign-in" component={SignInPage} exact />
      <Route
        path="/quotes/invitation/:type(bider|provider)/:token"
        component={AcceptInvitationPage}
        exact
      />
      <PrivateRoute component={Page404} />
    </Switch>
  );
};

export default RoutesInternals;
